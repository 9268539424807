@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@layer base {
    @font-face {
        font-family: Degular;
        font-weight: 100;
        src: url(/fonts/degular/Degular-100.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 300;
        src: url(/fonts/degular/Degular-300.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 400;
        src: url(/fonts/degular/Degular-400.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 500;
        src: url(/fonts/degular/Degular-500.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 600;
        src: url(/fonts/degular/Degular-600.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 700;
        src: url(/fonts/degular/Degular-700.otf) format("opentype");
    }
    @font-face {
        font-family: Degular;
        font-weight: 900;
        src: url(/fonts/degular/Degular-900.otf) format("opentype");
    }

    .break-word {
        word-break: break-word;
    }

    .wrap-anywhere {
        overflow-wrap: anywhere;
    }
}

body {
    margin: 0;
    font-family: Degular, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 1280px) {
    .drawer:hover ~ .drop {
        width: 100%;
    }
}
